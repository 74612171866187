import React from "react";
import "../Css/HomeWedding.css";
import demo01 from "../Assert/Product/SH15625.jpg";
import demo02 from "../Assert/Product/LI17043-2.jpg";
const HomeWedding = () => {
  return (
    <>
      <section className="home-section-wedding">
        <div className="container">
          <div className="home-section-wedding-main-con">

            <div className="row">
              <div className="col-md-3 col-sm-6 order-1 order-sm-1 order-md-1 home-wedding-images-main-con">
                <div className="home-section-wedding-first-main-image-con">
                  <div className="home-section-wedding-first-main-image home-section-wedding-first-main-image-left">
                    <img src={demo01} alt="demo01" />
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 order-2 order-sm-3 order-md-2">
                <div className="home-section-wedding-main-details-main-con">
                  <div className="home-section-wedding-main-details">
                    <div className="home-section-head-main-con">
                      <h2>Top Selling Collections</h2>
                      <h3>SHOP BY CATEGORY</h3>
                    </div>
                    <p>
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Natus corporis magnam suscipit tempora autem. Temporibus
                      nulla quo nihil? Quis sunt iusto quas libero. Assumenda
                      placeat aliquam eius, iusto suscipit dicta consectetur
                      temporibus recusandae nostrum tenetur, harum ut in
                      accusantium sed.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 order-3 order-sm-2 order-md-3 home-wedding-images-main-con">
                <div className="home-section-wedding-first-main-image-con">
                  <div className="home-section-wedding-first-main-image home-section-wedding-first-main-image-right">
                    <img src={demo02} alt="demo01" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeWedding;

import logo from './logo.svg';
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import Header from './Components/Header';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Components/Home';
import Product from './Pages/Product';
import ProductDetailsPages from './Pages/ProductDetailsPages';
import Account from './Pages/Account';
import CheckOutPage from './Pages/CheckOutPage';
import Profiles from "./Components/Profile/Profiles";
import SuccessPage from "./Pages/SuccessPage";
import FailedPage from "./Pages/FailedPage";
import AboutPage from './Pages/AboutPage';
import ContactPage from './Pages/ContactPage';
import OfferSwiper from './Components/OfferSwiper';
import PaymentPolicy from './Components/Policies/PaymentPolicy';
import PrivacyPolicie from './Components/Policies/PrivacyPolicie';
import ShippingPolicy from './Components/Policies/ShippingPolicy';
import TermsofService from './Components/Policies/TermsofService';
import RefundPolicy from './Components/Policies/RefundPolicy';
import Faq from './Components/Policies/Faq';
import Loader from './Pages/Loader';
import { useEffect, useState } from 'react';
import AudioPlayer from './Pages/AudioPlayer';

function App() {

  const [ loading, setLoading] = useState(true)
  useEffect(()=>{
    const timer = setTimeout(() => {
      setLoading(false)
    }, 3000);
    return () => clearTimeout(timer);
  },[])

  return (
    <div className="App">
    <div className={`content ${loading ? 'loading' : ''}`}>
    <BrowserRouter>
      {/* <Loader/> */}
      <AudioPlayer/>
        <OfferSwiper />
        <Header />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/profiles' element={<Profiles />} />
          <Route path='/product/:slug?' element={<Product />} />
          <Route path='/contact-page' element={<ContactPage />} />
          <Route path='/About-us' element={<AboutPage />} />
          <Route path='/product-detail-page/:slug' element={<ProductDetailsPages />} />
          <Route path='/checkOutPage' element={<CheckOutPage />} />
          <Route path='/account/:page' element={<Account />} />
          <Route path="/success-page/:id" element={<SuccessPage />} />
          <Route path="/PaymentPolicy" element={<PaymentPolicy />} />
          <Route path="/PrivacyPolicie" element={<PrivacyPolicie />} />
          <Route path="/ShippingPolicy" element={<ShippingPolicy />} />
          <Route path="/TermsofService" element={<TermsofService />} />
          <Route path="/RefundPolicy" element={<RefundPolicy />} />
          {/* <Route path="/faqs" element={<Faq />} /> */}
          <Route path="/fail-page/:id" element={<FailedPage />} />
        </Routes>
      </BrowserRouter>
      </div>
      {loading && <Loader />}
      </div>
  );
}

export default App;

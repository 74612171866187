import React, { useEffect, useState } from 'react'
import "../Css/ClothCard.css";

const ClothCard = ({ pPrice, pImage, pName, pImagesSecond, pDes }) => {
  const [backgroundImage, setBackgroundImage] = useState(`url(${pImage})`);

  const handleMouseEnter = () => {
    setBackgroundImage(`url(${pImagesSecond})`);
  };

  const handleMouseLeave = () => {
    setBackgroundImage(`url(${pImage})`);
  };

  useEffect(() => {
    setBackgroundImage(`url(${pImage})`)
  }, [pImage])
  return (
    <>
      <div className='producct-main-card-body-con'>
        <div className='producct-main-card-body'>
          <div
            className="main-card-product reletive"
            style={{ backgroundImage, width: "100%", backgroundSize: "cover", margin: "10px 0px", backgroundPosition: "center" }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >

            <a className='card-product-shop-now'>
              Shop Now
            </a>
          </div>


          <div className='clothCard-product-deatils-main-con'>
            <span className='clothCard-product-title'>
              {pName}
            </span>
            {/* <p>
              {pDes}
            </p> */}
            <div className='clothCard-product-deatils-main-con-price'>
              <div className='clothCard-product-deatils-main-con-mainprice'>
                <label > RS. {pPrice} INR </label>
              </div>
              <div className='clothCard-product-deatils-main-con-offerprice'>
                <label > RS. {pPrice} INR </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ClothCard
import React from 'react'
import Footer from '../Footer'
import "../../Css/PrivacyPolicie.css"

const RefundPolicy = () => {
    return (
        <>
            <div className='container'>
                <div className='privacy-policy-section-details'>
                    <h2 className='mt-5'>Refund Policy</h2>
                    <p>
                        100% Purchase Protection | Happiness Guarantee
                    </p>
                    <p>
                        We are committed to ensuring 100% purchase protection for customers by offering genuine products, secure payments, and easy exchange for items shopped on Kataria Store
                        We stand by our product 100%, no questions asked. If you have a problem, we will solve it.
                    </p>
                    <p>
                        Just reach out to our customer happiness team, and we will review and honour the exchange from the time you receive the items.
                    </p>


                    <h3 className='privacy-heading'>Eligibility for Exchange</h3>
                    <p>Items are only eligible for exchange if they are in their original condition with all tags and labels attached, and have not been washed, worn, altered, or damaged.</p>

                    <h3 className='privacy-heading'>Exchange Process</h3>
                    <p>To initiate an exchange, please contact our customer service team after receiving the item.</p>
                    <p>Exchanges are subject to availability, and we cannot guarantee that the size you need will be in stock. If the size you need is not available, we will issue you a store credit.</p>
                    <p>The customer is responsible for shipping the item back to us for exchange. We recommend using a tracked and insured shipping service to ensure the safe arrival of the item.</p>
                    <p>Once an article is received at our warehouse, it will undergo a quality check for eligibility for exchange. You will be notified by email about the approval status.</p>
                    <p>After we receive the exchange item, we will ship the exchanged item back to you via our standard shipping method.</p>
                    <p>For more details yo can contact at +91 9321032490 or email us at Katariasstore@gmail.</p>


                    <h3 className='privacy-heading'>SALE Items Purchase</h3>
                    <p>Returns and exchanges are not available for sale products purchased. This policy ensures we can offer you the best possible prices. We recommend reviewing size and descriptions carefully before your purchase. If you have any questions about sizing or fit, please contact us before placing your order.</p>
                    The customer is responsible for shipping the item back to us for exchange. We recommend using a tracked and insured shipping service to ensure the safe arrival of the item.
                    <p>Once an article is received at our warehouse, it will undergo a quality check for eligibility for exchange. You will be notified by email about the approval status.
                    </p>


                    <h3 className='privacy-heading'>Can I Exchange Products Purchased During the Sale?</h3>
                    <p>Products purchased during or on sale will not be eligible for exchange. Made-to-order (MTO) or customized items cannot be exchanged or returned. Altered garments, customized styles, and stitched blouses are not returnable.</p>

                    <h3 className='privacy-heading'>Can I Exchange Products Purchased During FaceTime or Skype Call?</h3>
                    <p>Products purchased during or on FaceTime or Skype calls are only eligible for exchange. No refunds are provided for such purchases as each product is shown to customers properly.</p>

                    <h3 className='privacy-heading'>How can I claim my exchange?</h3>
                    <p>For exchange requests, please log in to your account and follow the prompts to initiate your exchange. If you need assistance, feel free to contact us at Katariasstore@gmail.com or call +91 9321032490 at directly. Our team is here to help with any questions or concerns you may have regarding your exchange. You can also visit our Katariya stores located in Koppikar Rd, New Hubli, Hubballi, Karnataka 580020, to exchange items in person. We aim to make the exchange process as smooth as possible, so don’t hesitate to reach out for support. Your satisfaction is our priority, and we’re here to assist you every step of the way.</p>
                    <h3 className='privacy-heading'>Additional Information</h3>
                    <p>Dispatch time will be 7-10 days depending on the product.</p>
                    <p>Products which fall under made-to-measure and customization categories are not applicable for refund or exchange.</p>

                    <h3 className='privacy-heading'>Delivery Charges</h3>
                    <p>Delivery charges will be deducted from your total payment. Delivery charges are non-refundable.</p>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default RefundPolicy
import React, { useEffect, useRef, useState } from 'react';
import audioFile from '../Assert/demo.mp3';
import "../Css/AudioPlayer.css";
import { IoVolumeMedium } from "react-icons/io5";
import { IoVolumeMute } from "react-icons/io5";

const AudioPlayer = () => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = () => {
    audioRef.current.play().then(() => {
      setIsPlaying(true);
    }).catch(error => {
      console.error("Autoplay prevented. User interaction required.");
    });
  };

  const handleStop = () => {
    audioRef.current.pause();
    audioRef.current.currentTime = 0; // Reset to start
    setIsPlaying(false);
  };

  useEffect(() => {
    // Automatically play when the component mounts
    handlePlay();
  }, []);

  return (
    <div className='audio'>
      <audio ref={audioRef} src={audioFile} preload="auto" />
      <button onClick={isPlaying ? handleStop : handlePlay}>
        {isPlaying ? <IoVolumeMedium /> : <IoVolumeMute />}
      </button>
    </div>
  );
};

export default AudioPlayer;

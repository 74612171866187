import React, { useLayoutEffect } from "react";
import "../Css/AboutUs.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { AiFillHome } from "react-icons/ai";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Autoplay,Pagination, Navigation } from "swiper/modules";
import Footer from "../Components/Footer";
import aboutusKataria from "../Assert/aboutKatariaNew.jpeg";
import aboutUsGallery1 from "../Assert/Gallery/DSC09085.jpg";
import aboutUsGallery2 from "../Assert/Gallery/DSC09089.jpg";
import aboutUsGallery3 from "../Assert/Gallery/DSC09103.jpg";
import aboutUsGallery4 from "../Assert/Gallery/DSC09111.jpg";
import aboutUsGallery5 from "../Assert/Gallery/DSC09129.jpg";
import aboutUsGallery6 from "../Assert/Gallery/DSC09130.jpg";
import aboutUsGallery7 from "../Assert/Gallery/DSC09134.jpg";
import aboutUsGallery8 from "../Assert/Gallery/DSC09135.jpg";
import aboutUsGallery9 from "../Assert/Gallery/DSC09152.jpg";
import aboutUsGallery10 from "../Assert/Gallery/DSC09159.jpg";
import aboutUsGallery11 from "../Assert/Gallery/DSC09161.jpg";
import aboutUsGallery12 from "../Assert/Gallery/DSC09165.jpg";
import aboutUsGallery13 from "../Assert/Gallery/DSC09169.jpg";
import aboutUsGallery14 from "../Assert/Gallery/DSC09177.jpg";
import aboutUsGallery15 from "../Assert/Gallery/DSC09180-2.jpg";
import aboutUsGallery16 from "../Assert/Gallery/DSC09188.jpg";
import aboutUsGallery17 from "../Assert/Gallery/DSC09191.jpg";
import aboutUsGallery18 from "../Assert/Gallery/DSC09194.jpg";
import aboutUsGallery19 from "../Assert/Gallery/DSC09197.jpg";
import aboutUsGallery20 from "../Assert/Gallery/DSC09203.jpg";
import aboutUsGallery21 from "../Assert/Gallery/DSC09208.jpg";
import aboutUsGallery22 from "../Assert/Gallery/DSC09213.jpg";
import { Image } from "antd";
import CustomerReviewCard from "../Components/CustomerReviewCard";
import reviews from "../Assert/reviews";

const AboutPage = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      {/* ---------------- Section 1 ---------------------- */}
      <section className="about-us-new-Section-1">
        <div className="container">
          <div className="Contact-Section-con">
            <div className="about-us-new-Section-Text">
              <h2>About Us</h2>
              <div className="about-us-new-Section-NavPage">
                <span className="d-flex">
                  <AiFillHome />
                  Home
                </span>
                <h3>
                  <MdKeyboardArrowRight />
                </h3>
                <h1 className="d-none">Best Salon in Ghatkopar</h1>
                <h2>About Us</h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ///////////////// Section 1 ///////////////////// */}

      <section className="AboutUs-Section-1">
        <div className="container">
          <div className="AboutUs-Section-1-con">
            <div className="row">
              <div className="col-md-4 ">
                <div className="About-Section-1-Image-con">
                  <div className="About-Section-1-Image">
                    <img src={aboutusKataria} />
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="About-Section-1-Details">
                  <div className="About-Section-1-Details-con">
                    <h2 className="Smalltitle">About Us</h2>
                    {/* <h3 className="Headtitle">
                      Online shopping includes both buying things online.
                    </h3> */}
                    <p className="text-center">
                      Welcome to Katarias, your one-stop destination for all
                      your fashion needs! At Katarias, we offer a diverse and
                      stylish collection of suits, sherwanis, suiting, and
                      shirting options that cater to every occasion and style
                      preference. Our store is a treasure trove of high-quality
                      garments crafted with precision and attention to detail.
                      Whether you're searching for a classic suit for a formal
                      event or a majestic sherwani for a cultural celebration,
                      Katarias has something for everyone.
                    </p>
                    <p className="text-center">
                      Visit us in Hubballi to experience personalized service,
                      top-notch quality, and a shopping experience that will
                      elevate your wardrobe game. Let Katarias be your fashion
                      partner in creating memorable and fashionable looks for
                      every moment in your life! Now to serve you all immensely
                      we are back with our online website from which you can
                      order your apparel from any part of the world.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="home-our-categories-section-head">
            <h4 className="text-under-line">A Glimpse into Our Store</h4>
          </div>
          <div className="about-section-gallery-main-con">
            <Swiper
              slidesPerView={2}
              spaceBetween={10}
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
            }}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 2,
                  spaceBetween: 50,
                },
              }}
              modules={[Autoplay,Pagination]}
              className="mySwiper"
            >
              <SwiperSlide>
                <Image
                  className="w-100 h-100"
                  // width=
                  src={aboutUsGallery1}
                />
              </SwiperSlide>
              <SwiperSlide>
                <Image
                  className="w-100 h-100"
                  // width=
                  src={aboutUsGallery2}
                />
              </SwiperSlide>
              <SwiperSlide>
                <Image
                  className="w-100 h-100"
                  // width=
                  src={aboutUsGallery3}
                />
              </SwiperSlide>
              <SwiperSlide>
                <Image
                  className="w-100 h-100"
                  // width=
                  src={aboutUsGallery4}
                />
              </SwiperSlide>
              <SwiperSlide>
                <Image
                  className="w-100 h-100"
                  // width=
                  src={aboutUsGallery5}
                />
              </SwiperSlide>
              <SwiperSlide>
                <Image
                  className="w-100 h-100"
                  // width=
                  src={aboutUsGallery6}
                />
              </SwiperSlide>
              <SwiperSlide>
                <Image
                  className="w-100 h-100"
                  // width=
                  src={aboutUsGallery7}
                />
              </SwiperSlide>
              <SwiperSlide>
                <Image
                  className="w-100 h-100"
                  // width=
                  src={aboutUsGallery8}
                />
              </SwiperSlide>
              <SwiperSlide>
                <Image
                  className="w-100 h-100"
                  // width=
                  src={aboutUsGallery9}
                />
              </SwiperSlide>
              <SwiperSlide>
                <Image
                  className="w-100 h-100"
                  // width=
                  src={aboutUsGallery10}
                />
              </SwiperSlide>
              <SwiperSlide>
                <Image
                  className="w-100 h-100"
                  // width=
                  src={aboutUsGallery11}
                />
              </SwiperSlide>
              <SwiperSlide>
                <Image
                  className="w-100 h-100"
                  // width=
                  src={aboutUsGallery12}
                />
              </SwiperSlide>
              <SwiperSlide>
                <Image
                  className="w-100 h-100"
                  // width=
                  src={aboutUsGallery13}
                />
              </SwiperSlide>
              <SwiperSlide>
                <Image
                  className="w-100 h-100"
                  // width=
                  src={aboutUsGallery14}
                />
              </SwiperSlide>
              <SwiperSlide>
                <Image
                  className="w-100 h-100"
                  // width=
                  src={aboutUsGallery15}
                />
              </SwiperSlide>
              <SwiperSlide>
                <Image
                  className="w-100 h-100"
                  // width=
                  src={aboutUsGallery16}
                />
              </SwiperSlide>
              <SwiperSlide>
                <Image
                  className="w-100 h-100"
                  // width=
                  src={aboutUsGallery17}
                />
              </SwiperSlide>
              <SwiperSlide>
                <Image
                  className="w-100 h-100"
                  // width=
                  src={aboutUsGallery18}
                />
              </SwiperSlide>
              <SwiperSlide>
                <Image
                  className="w-100 h-100"
                  // width=
                  src={aboutUsGallery19}
                />
              </SwiperSlide>
              <SwiperSlide>
                <Image
                  className="w-100 h-100"
                  // width=
                  src={aboutUsGallery20}
                />
              </SwiperSlide>
              <SwiperSlide>
                <Image
                  className="w-100 h-100"
                  // width=
                  src={aboutUsGallery21}
                />
              </SwiperSlide>
              <SwiperSlide>
                <Image
                  className="w-100 h-100"
                  // width=
                  src={aboutUsGallery22}
                />
              </SwiperSlide>
            </Swiper>

          </div>
        </div>
      </section>
      {/* Gallery end*/}

      {/* ///////////////////// Section 2 ///////////////////// */}

      <section className="AboutUs-Section-2">
        <div className="container">
          <div className="AboutUs-Section-Counter">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-6">
                <div className="AboutUs-Section-Counter-card">
                  <div className="AboutUs-Section-Counter-Image-con">
                    <div className="AboutUs-Section-Counter-Image">
                      <img
                        src="https://jutiepie.in/static/media/like-button.e0418aee5cf9efaa47ea.png"
                      // {HappyCustomers}
                      />
                    </div>
                  </div>
                  <div className="AboutUs-Section-Counter-card-details">
                    <h2>
                    30 + <span>years.</span>
                    </h2>
                    <h3>Legacy</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-6">
                <div className="AboutUs-Section-Counter-card">
                  <div className="AboutUs-Section-Counter-Image-con">
                    <div className="AboutUs-Section-Counter-Image">
                      <img
                        src="https://jutiepie.in/static/media/people.9b509fbdd4993e39818d.png"
                      // {FoundingYear}
                      />
                    </div>
                  </div>
                  <div className="AboutUs-Section-Counter-card-details">
                    <h2>1989</h2>
                    <h3>Founding Year</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-6">
                <div className="AboutUs-Section-Counter-card">
                  <div className="AboutUs-Section-Counter-Image-con">
                    <div className="AboutUs-Section-Counter-Image">
                      <img
                        src="https://jutiepie.in/static/media/delivery-van.b36993ea8115f5c5201f.png"
                      // {ProductOrders}
                      />
                    </div>
                  </div>
                  <div className="AboutUs-Section-Counter-card-details">
                    <h2>
                      120 <span>+</span>
                    </h2>
                    <h3>Exclusive men’s store.</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-6">
                <div className="AboutUs-Section-Counter-card">
                  <div className="AboutUs-Section-Counter-Image-con">
                    <div className="AboutUs-Section-Counter-Image">
                      <img
                        src="https://jutiepie.in/static/media/medal.b1a4472e2aa07ccef4ae.png"
                      // {QualityProducts}
                      />
                    </div>
                  </div>
                  <div className="AboutUs-Section-Counter-card-details">
                    <h2>
                      60 <span>+</span>
                    </h2>
                    <h3>Trust And Quality.</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* //////////////////////////////// Section 7 ////////////////////// */}

      <section className="Home-Section-7">
        <div className="container">
        <div className="home-section-head-main-con">
            <h2>Top Selling Collections</h2>
            <h3>SHOP BY CATEGORY</h3>
          </div>
          <div className="Home-Section-7-testimonial">
            <Swiper
              slidesPerView={1}
              spaceBetween={10}
              autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 2,
                  spaceBetween: 50,
                },
              }}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper"
            >
              {reviews.map((review, index) => (
                <SwiperSlide key={index}>
                  <CustomerReviewCard
                    description={review.reviews}
                    rate={review.stars}
                    name={review.user_name}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default AboutPage;

import React from "react";
import "../Css/HomeBanner.css";
import image01 from "../Assert/banner/banner01.png";
import image02 from "../Assert/banner/banner02.png";

const HomeBanner = (() => {
    return (
        <>
            <section className="new-home-banner-section">
                <div className="new-home-banner-main-con">
                    <div className="container">
                        <div className="row">
                            <div className="col-3">
                                <div className="new-banner-image-main-con">
                                    <div className="new-banner-image-con">
                                        <img src={image01} alt="image01" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="new-banner-details-main-con">
                                    <div className="new-banner-details-con">
                                        <h2>More Than Fashion</h2>
                                        <h2>It's a Lifestyle</h2>
                                        <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit.</h3>
                                        <div className="new-banner-details-btn-con">
                                            <button>
                                                Read More
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-3">
                                <div className="new-banner-image-main-con">
                                    <div className="new-banner-image-con">
                                        <img src={image02} alt="image01" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
});

export default HomeBanner;